// import React, { useEffect } from 'react';
// import './style/Herosection.css';
// import { createAndRedirectToNewHub } from '../../utils/phoenix-utils';

// const HeroSection = () => {
//   useEffect(() => {
//     console.log("HeroSection mounted");
//   }, []);

//   const handleExploreClick = () => {
//     console.log("Explore button clicked");
//     createAndRedirectToNewHub(null, null, true);
//   };

//   return React.createElement(
//     'header',
//     { className: 'hero-section' }, // The background image will be set in the CSS
//     React.createElement(
//       'div',
//       { className: 'hero-content' },
//       React.createElement(
//         'h1',
//         { className: 'company-name' },
//         'ZapAura'
//       ),
//       React.createElement(
//         'p',
//         { className: 'tagline' },
//         'Explore, connect, create: The Metaverse Awaits'
//       ),
//       React.createElement(
//         'button',
//         {
//           onClick: handleExploreClick,
//           className: 'btn btn-primary explore-button'
//         },
//         'Try Demo'
//       )
//     )
//   );
// };

// export default HeroSection;

import React, { useEffect, useState, useContext } from 'react';
import { AuthContext } from '../auth/AuthContext';
import './style/Herosection.css';

const HeroSection = () => {
  const auth = useContext(AuthContext);
  const [isUserAdmin, setIsUserAdmin] = useState(false);

  useEffect(() => {
    setIsUserAdmin(auth.isAdmin);
  }, [auth.isAdmin]);

  const handleCreateSceneClick = () => {
    if (isUserAdmin) {
      // If user is admin, redirect to the scene editor
      console.log("Admin user: Redirecting to create scene");
      window.location.href = '/spoke'; // Change to your scene editor URL
    } else {
      // If user is not an admin, show a warning
      alert("You are not an admin. Access to this feature is restricted.");
    }
  };

  return (
    <header className='hero-section'>
      <div className='hero-content'>
        <h1 className='company-name'>ZapAura</h1>
        <p className='tagline'>Explore, connect, create: The Metaverse Awaits</p>
        <button
          onClick={handleCreateSceneClick}
          className='btn btn-primary explore-button'
        >
          Create Scene
        </button>
      </div>
    </header>
  );
};

export default HeroSection;