
import './style/Footer.css'; // Ensure you have this CSS file
import React, { useEffect, useState } from 'react';
const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-bottom">
                <p>&copy; {new Date().getFullYear()} ZapAura. All Rights Reserved.</p>
            </div>
        </footer>
    );
};

export default Footer;