// import React, { useEffect, useState } from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import './style/Imagegallery.css';
// import heroImage from '../../assets/images/devices_icon.png';
// import elg5121Image from '../../assets/images/elg5121.jpg'; // Import the ELG5121 image
// import { usePublicRooms } from '../home/usePublicRooms';
// import { scaledThumbnailUrlFor } from '../../utils/media-url-utils';

// const ImageGallery = () => {
//   const { results: publicRooms, loading, error } = usePublicRooms();
//   const [roomsData, setRoomsData] = useState([]);
//   const [showIframe, setShowIframe] = useState(false);

//   useEffect(() => {
//     if (publicRooms && publicRooms.length > 0) {
//       const formattedRooms = publicRooms
//         .sort((a, b) => b.member_count - a.member_count)
//         .map((room) => ({
//           img: scaledThumbnailUrlFor(room.images?.preview?.url || heroImage),
//           url: `/rooms/${room.id}`,
//           title: room.name || "Untitled Room",
//           memberCount: room.member_count,
//           labels: room.labels || []
//         }));

//       setRoomsData(formattedRooms);
//     }
//   }, [publicRooms]);

//   const handleELG5121Click = () => {
//     setShowIframe(true);
//   };

//   const closeIframe = () => {
//     setShowIframe(false);
//   };

//   if (loading) return <div>Loading...</div>;
//   if (error) return <div>Error: {error.message}</div>;

//   const lectureRooms = roomsData.filter(room => 
//     room.title.toLowerCase().includes('lecture') || 
//     room.labels.some(label => label.toLowerCase().includes('lecture'))
//   );

//   return (
//     <div id="gallery" className="introtitle">
//       <div className="gallery-header">
//         <span className="expmessage">Experiences made for</span>
//         <img src={heroImage} alt="Hero" className="below-title-image" />
//         <h1 className="introtitle_">Explore and uncover the hidden worlds of space</h1>
//       </div>
//       <div className="gallery-container">
//         {/* ELG5121 Card */}
//         <div className="gallery-card" onClick={handleELG5121Click}>
//           <div className="gallery-card-inner">
//             <img src={elg5121Image} alt="ELG5121" className="card-image" />
//             <div className="gallery-card-label">
//               <h3>ELG5121</h3>
//             </div>
//           </div>
//         </div>

//         {/* Regular room cards */}
//         {roomsData.map((room) => (
//           <div key={room.url} className="gallery-card" onClick={() => window.open(room.url, '_blank')}>
//             <div className="gallery-card-inner">
//               <img src={room.img} alt={room.title} className="card-image" />
//               <div className="gallery-card-label">
//                 <h3>{room.title}</h3>
//                 {/* <p>Members: {room.memberCount}</p> */}
//               </div>
//             </div>
//           </div>
//         ))}
//       </div>

//       {/* Iframe Modal */}
//       {showIframe && (
//         <div className="iframe-modal">
//           <div className="iframe-content">
//             <button onClick={closeIframe} className="close-button">×</button>
//             <h2>ELG5121 Lecture Rooms</h2>
//             <div className="lecture-rooms-list">
//               {lectureRooms.map((room) => (
//                 <div
//                   key={room.url}
//                   className="lecture-room-item"
//                   onClick={() => window.open(room.url, '_blank')}
//                 >
//                   <img src={room.img} alt={room.title} />
//                   <span>{room.title}</span>
//                 </div>
//               ))}
//             </div>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default ImageGallery;

// import React, { useEffect, useState } from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import './style/Imagegallery.css';
// import heroImage from '../../assets/images/devices_icon.png';
// import elg5121Image from '../../assets/images/elg5121.jpg'; // Import the ELG5121 image
// import { usePublicRooms } from '../home/usePublicRooms';
// import { scaledThumbnailUrlFor } from '../../utils/media-url-utils';

// const ImageGallery = () => {
//   const { results: publicRooms, loading, error } = usePublicRooms();
//   const [roomsData, setRoomsData] = useState([]);
//   const [lectureRooms, setLectureRooms] = useState([]);
//   const [showIframe, setShowIframe] = useState(false);

//   useEffect(() => {
//     if (publicRooms && publicRooms.length > 0) {
//       const formattedRooms = publicRooms
//         .sort((a, b) => b.member_count - a.member_count)
//         .map((room) => ({
//           img: scaledThumbnailUrlFor(room.images?.preview?.url || heroImage),
//           url: `/rooms/${room.id}`,
//           title: room.name || "Untitled Room",
//           memberCount: room.member_count,
//           labels: room.labels || []
//         }));

//       // Separate lecture rooms from the others
//       const filteredLectureRooms = formattedRooms.filter(room => 
//         room.title.toLowerCase().includes('lecture') || 
//         room.labels.some(label => label.toLowerCase().includes('lecture'))
//       );

//       const nonLectureRooms = formattedRooms.filter(room => 
//         !room.title.toLowerCase().includes('lecture') &&
//         !room.labels.some(label => label.toLowerCase().includes('lecture'))
//       );

//       setRoomsData(nonLectureRooms);  // Non-lecture rooms for the main gallery
//       setLectureRooms(filteredLectureRooms);  // Lecture rooms for the iframe
//     }
//   }, [publicRooms]);

//   const handleELG5121Click = () => {
//     setShowIframe(true);
//   };

//   const closeIframe = () => {
//     setShowIframe(false);
//   };

//   if (loading) return <div>Loading...</div>;
//   if (error) return <div>Error: {error.message}</div>;

//   return (
//     <div id="gallery" className="introtitle">
//       <div className="gallery-header">
//         <span className="expmessage">Experiences made for</span>
//         <img src={heroImage} alt="Hero" className="below-title-image" />
//         <h1 className="introtitle_">Explore and uncover the hidden worlds of space</h1>
//       </div>
//       <div className="gallery-container">
//         {/* ELG5121 Card */}
//         <div className="gallery-card" onClick={handleELG5121Click}>
//           <div className="gallery-card-inner">
//             <img src={elg5121Image} alt="ELG5121" className="card-image" />
//             <div className="gallery-card-label">
//               <h3>ELG5121</h3>
//             </div>
//           </div>
//         </div>

//         {/* Non-lecture room cards */}
//         {roomsData.map((room) => (
//           <div key={room.url} className="gallery-card" onClick={() => window.open(room.url, '_blank')}>
//             <div className="gallery-card-inner">
//               <img src={room.img} alt={room.title} className="card-image" />
//               <div className="gallery-card-label">
//                 <h3>{room.title}</h3>
//               </div>
//             </div>
//           </div>
//         ))}
//       </div>

//       {/* Iframe Modal */}
//       {showIframe && (
//         <div className="iframe-modal">
//           <div className="iframe-content">
//             <button onClick={closeIframe} className="close-button">×</button>
//             <h2>ELG5121 Lecture Rooms</h2>
//             <div className="lecture-rooms-list">
//               {lectureRooms.map((room) => (
//                 <div
//                   key={room.url}
//                   className="lecture-room-item"
//                   onClick={() => window.open(room.url, '_blank')}
//                 >
//                   <img src={room.img} alt={room.title} />
//                   <span>{room.title}</span>
//                 </div>
//               ))}
//             </div>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default ImageGallery;

// import React, { useEffect, useState } from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import './style/Imagegallery.css';
// import heroImage from '../../assets/images/devices_icon.png';
// import elg5121Image from '../../assets/images/elg5121.jpg'; // Import the ELG5121 image
// import { usePublicRooms } from '../home/usePublicRooms';
// import { scaledThumbnailUrlFor } from '../../utils/media-url-utils';

// const ImageGallery = () => {
//   const { results: publicRooms, loading, error } = usePublicRooms();
//   const [roomsData, setRoomsData] = useState([]);
//   const [showIframe, setShowIframe] = useState(false);

//   useEffect(() => {
//     if (publicRooms && publicRooms.length > 0) {
//       const formattedRooms = publicRooms
//         .sort((a, b) => b.member_count - a.member_count)
//         .map((room) => ({
//           img: scaledThumbnailUrlFor(room.images?.preview?.url || heroImage),
//           url: `/${room.id}/lecture-room`,  // Adjusting the room link format to match HomePage.js
//           title: room.name || "Untitled Room",
//           memberCount: room.member_count,
//           labels: room.labels || []
//         }));

//       setRoomsData(formattedRooms);
//     }
//   }, [publicRooms]);

//   const handleELG5121Click = () => {
//     setShowIframe(true);
//   };

//   const closeIframe = () => {
//     setShowIframe(false);
//   };

//   if (loading) return <div>Loading...</div>;
//   if (error) return <div>Error: {error.message}</div>;

//   return (
//     <div id="gallery" className="introtitle">
//       <div className="gallery-header">
//         <span className="expmessage">Experiences made for</span>
//         <img src={heroImage} alt="Hero" className="below-title-image" />
//         <h1 className="introtitle_">Explore and uncover the hidden worlds of space</h1>
//       </div>
//       <div className="gallery-container">
//         {/* ELG5121 Card */}
//         <div className="gallery-card" onClick={handleELG5121Click}>
//           <div className="gallery-card-inner">
//             <img src={elg5121Image} alt="ELG5121" className="card-image" />
//             <div className="gallery-card-label">
//               <h3>ELG5121</h3>
//             </div>
//           </div>
//         </div>

//         {/* Room gallery cards */}
//         {roomsData.map((room) => (
//           <div key={room.url} className="gallery-card" onClick={() => window.open(room.url, '_blank')}>
//             <div className="gallery-card-inner">
//               <img src={room.img} alt={room.title} className="card-image" />
//               <div className="gallery-card-label">
//                 <h3>{room.title}</h3>
//               </div>
//             </div>
//           </div>
//         ))}
//       </div>

//       {/* Iframe Modal for Lecture Rooms */}
//       {showIframe && (
//         <div className="iframe-modal">
//           <div className="iframe-content">
//             <button onClick={closeIframe} className="close-button">×</button>
//             <h2>ELG5121 Lecture Rooms</h2>
//             <div className="lecture-rooms-list">
//               {roomsData
//                 .filter(room => room.title.toLowerCase().includes('lecture'))
//                 .map((room) => (
//                   <div
//                     key={room.url}
//                     className="lecture-room-item"
//                     onClick={() => window.open(room.url, '_blank')}
//                   >
//                     <img src={room.img} alt={room.title} />
//                     <span>{room.title}</span>
//                   </div>
//                 ))}
//             </div>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default ImageGallery;

// import React, { useEffect, useState } from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import './style/Imagegallery.css';
// import heroImage from '../../assets/images/devices_icon.png';
// import elg5121Image from '../../assets/images/elg5121.jpg'; // Import the ELG5121 image
// import { usePublicRooms } from '../home/usePublicRooms';
// import { scaledThumbnailUrlFor } from '../../utils/media-url-utils';

// const ImageGallery = () => {
//   const { results: publicRooms, loading, error } = usePublicRooms();
//   const [nonLectureRooms, setNonLectureRooms] = useState([]);  // For rooms not containing "lecture"
//   const [lectureRooms, setLectureRooms] = useState([]);  // For rooms containing "lecture"
//   const [showIframe, setShowIframe] = useState(false);

//   useEffect(() => {
//     if (publicRooms && publicRooms.length > 0) {
//       const formattedRooms = publicRooms
//         .sort((a, b) => b.member_count - a.member_count)
//         .map((room) => ({
//           img: scaledThumbnailUrlFor(room.images?.preview?.url || heroImage),
//           url: `/${room.id}/lecture-room`,  // Adjusting the room link format to match HomePage.js
//           title: room.name || "Untitled Room",
//           memberCount: room.member_count,
//           labels: room.labels || []
//         }));

//       // Filter lecture rooms
//       const filteredLectureRooms = formattedRooms.filter(room => 
//         room.title.toLowerCase().includes('lecture')
//       );
      
//       // Filter non-lecture rooms
//       const filteredNonLectureRooms = formattedRooms.filter(room => 
//         !room.title.toLowerCase().includes('lecture')
//       );

//       setLectureRooms(filteredLectureRooms);  // Set lecture rooms in state for iframe
//       setNonLectureRooms(filteredNonLectureRooms);  // Set non-lecture rooms in state for gallery
//     }
//   }, [publicRooms]);

//   const handleELG5121Click = () => {
//     setShowIframe(true);
//   };

//   const closeIframe = () => {
//     setShowIframe(false);
//   };

//   if (loading) return <div>Loading...</div>;
//   if (error) return <div>Error: {error.message}</div>;

//   return (
//     <div id="gallery" className="introtitle">
//       <div className="gallery-header">
//         <span className="expmessage">Experiences made for</span>
//         <img src={heroImage} alt="Hero" className="below-title-image" />
//         <h1 className="introtitle_">Explore and uncover the hidden worlds of space</h1>
//       </div>
//       <div className="gallery-container">
//         {/* ELG5121 Card */}
//         <div className="gallery-card" onClick={handleELG5121Click}>
//           <div className="gallery-card-inner">
//             <img src={elg5121Image} alt="ELG5121" className="card-image" />
//             <div className="gallery-card-label">
//               <h3>ELG5121</h3>
//             </div>
//           </div>
//         </div>

//         {/* Non-lecture room gallery cards */}
//         {nonLectureRooms.map((room) => (
//           <div key={room.url} className="gallery-card" onClick={() => window.open(room.url, '_blank')}>
//             <div className="gallery-card-inner">
//               <img src={room.img} alt={room.title} className="card-image" />
//               <div className="gallery-card-label">
//                 <h3>{room.title}</h3>
//               </div>
//             </div>
//           </div>
//         ))}
//       </div>

//       {/* Iframe Modal for Lecture Rooms */}
//       {showIframe && (
//         <div className="iframe-modal">
//           <div className="iframe-content">
//             <button onClick={closeIframe} className="close-button">×</button>
//             <h2>ELG5121 Lecture Rooms</h2>
//             <div className="lecture-rooms-list">
//               {lectureRooms.map((room) => (
//                 <div
//                   key={room.url}
//                   className="lecture-room-item"
//                   onClick={() => window.open(room.url, '_blank')}
//                 >
//                   <img src={room.img} alt={room.title} />
//                   <span>{room.title}</span>
//                 </div>
//               ))}
//             </div>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default ImageGallery;


import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './style/Imagegallery.css';
import heroImage from '../../assets/images/devices_icon.png';
import elg5121Image from '../../assets/images/elg5121.jpg'; // Import the ELG5121 image
import { usePublicRooms } from '../home/usePublicRooms';
import { scaledThumbnailUrlFor } from '../../utils/media-url-utils';

const ImageGallery = () => {
  const { results: publicRooms, loading, error } = usePublicRooms();
  const [nonLectureRooms, setNonLectureRooms] = useState([]);  // For rooms not containing "lecture"
  const [lectureRooms, setLectureRooms] = useState([]);  // For rooms containing "lecture"
  const [showIframe, setShowIframe] = useState(false);

  useEffect(() => {
    if (publicRooms && publicRooms.length > 0) {
      const formattedRooms = publicRooms
        .sort((a, b) => b.member_count - a.member_count)
        .map((room) => ({
          img: scaledThumbnailUrlFor(room.images?.preview?.url || heroImage),
          // Dynamically use room.url if it exists, or fallback to room.id
          url: room.url ? room.url : `/${room.id}`,  
          title: room.name || "Untitled Room",
          memberCount: room.member_count,
          labels: room.labels || []
        }));

      // Filter lecture rooms
      const filteredLectureRooms = formattedRooms.filter(room => 
        room.title.toLowerCase().includes('lecture')
      );
      
      // Filter non-lecture rooms
      const filteredNonLectureRooms = formattedRooms.filter(room => 
        !room.title.toLowerCase().includes('lecture')
      );

      setLectureRooms(filteredLectureRooms);  // Set lecture rooms in state for iframe
      setNonLectureRooms(filteredNonLectureRooms);  // Set non-lecture rooms in state for gallery
    }
  }, [publicRooms]);

  const handleELG5121Click = () => {
    setShowIframe(true);
  };

  const closeIframe = () => {
    setShowIframe(false);
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <div id="gallery" className="introtitle">
      <div className="gallery-header">
        <span className="expmessage">Experiences made for</span>
        <img src={heroImage} alt="Hero" className="below-title-image" />
        <h1 className="introtitle_">Explore and uncover the hidden worlds of space</h1>
      </div>
      <div className="gallery-container">
        {/* ELG5121 Card */}
        <div className="gallery-card" onClick={handleELG5121Click}>
          <div className="gallery-card-inner">
            <img src={elg5121Image} alt="ELG5121" className="card-image" />
            <div className="gallery-card-label">
              <h3>ELG5121</h3>
            </div>
          </div>
        </div>

        {/* Non-lecture room gallery cards */}
        {nonLectureRooms.map((room) => (
          <div key={room.url} className="gallery-card" onClick={() => window.open(room.url, '_blank')}>
            <div className="gallery-card-inner">
              <img src={room.img} alt={room.title} className="card-image" />
              <div className="gallery-card-label">
                <h3>{room.title}</h3>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Iframe Modal for Lecture Rooms */}
      {showIframe && (
        <div className="iframe-modal">
          <div className="iframe-content">
            <button onClick={closeIframe} className="close-button">×</button>
            <h2>ELG5121 Lecture Rooms</h2>
            <div className="lecture-rooms-list">
              {lectureRooms.map((room) => (
                <div
                  key={room.url}
                  className="lecture-room-item"
                  onClick={() => window.open(room.url, '_blank')}
                >
                  <img src={room.img} alt={room.title} />
                  <span>{room.title}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ImageGallery;
