import 'regenerator-runtime/runtime';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import React from "react";
import { createRoot } from "react-dom/client";
import { WrappedIntlProvider } from "./react-components/wrapped-intl-provider";
import registerTelemetry from "./telemetry";
import "./utils/theme";
import { AuthContextProvider } from "./react-components/auth/AuthContext";
import "./react-components/styles/global.scss";
import { ThemeProvider } from "./react-components/styles/theme";
import { store } from "./utils/store-instance";
import Navbar from './react-components/homePage/Navbar';
import HeroSection from './react-components/homePage/Herosection';
import ImageGallery from './react-components/homePage/Imagegallery';
import Footer from './react-components/homePage/Footer';
registerTelemetry("/home", "Hubs Home Page");

window.APP = { store };

function HomeRoot() {
  return (
    <WrappedIntlProvider>
      <ThemeProvider store={store}>
        <AuthContextProvider store={store}>
        <HeroSection />
          <Navbar />
          <ImageGallery />
          <Footer />
        </AuthContextProvider>
      </ThemeProvider>
    </WrappedIntlProvider>
  );
}

const container = document.getElementById("home-root");
const root = createRoot(container);
root.render(<HomeRoot />);
