import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useContext, useState, useEffect } from 'react';
import { AuthContext } from '../auth/AuthContext';
import './style/Navbar.css';

const Navbar = () => {
  const auth = useContext(AuthContext);
  const [isVerifying, setIsVerifying] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [showIframe, setShowIframe] = useState(false); // State to control iframe visibility

  useEffect(() => {
    setIsAdmin(auth.isAdmin);

    const qs = new URLSearchParams(window.location.search);

    if (qs.has("auth_topic")) {
      setIsVerifying(true);
      auth.verify({ 
        topic: qs.get("auth_topic"), 
        token: qs.get("auth_token"), 
        payload: qs.get("auth_payload") 
      }).then(() => {
        setIsVerifying(false);
        window.location = '/';
      }).catch(() => {
        setIsVerifying(false);
      });
    }
  }, [auth]);

  const handleCreateAvatarClick = () => {
    setShowIframe(true); // Show iframe when button is clicked
  };

  const handleCloseIframe = () => {
    setShowIframe(false); // Hide iframe when close button is clicked
  };

  return (
    <>
      <nav className='navbar navbar-expand-lg navbar-dark bg-dark fixed-top'>
        <div className='container-fluid'>
          <a className='navbar-brand' href='/'>
            ZapAura Immersive World
          </a>
          <button
            className='navbar-toggler'
            type='button'
            data-bs-toggle='collapse'
            data-bs-target='#navbarNav'
            aria-controls='navbarNav'
            aria-expanded='false'
            aria-label='Toggle navigation'
          >
            <span className='navbar-toggler-icon' />
          </button>
          <div className='collapse navbar-collapse' id='navbarNav'>
            <ul className='navbar-nav ms-auto'>
              <li className='nav-item'>
                <a
                  className={`nav-link ${
                    window.location.pathname === '/' ? 'active' : ''
                  }`}
                  aria-current='page'
                  href='/'
                >
                  Home
                </a>
              </li>
              <li className='nav-item'>
                {auth.isSignedIn && !isVerifying ? (
                  <a href='#' onClick={auth.signOut} className='nav-link'>
                    Sign Out
                  </a>
                ) : (
                  <a
                    href='#'
                    onClick={() => (window.location = '/signin')}
                    className='nav-link'
                  >
                    Sign In
                  </a>
                )}
              </li>
              {isAdmin && !isVerifying && (
                <li className='nav-item'>
                  <a
                    className={`nav-link ${
                      window.location.pathname === '/admin' ? 'active' : ''
                    }`}
                    href='/admin'
                  >
                    Admin-Dashboard
                  </a>
                </li>
              )}
              <li className='nav-item'>
                <button
                  onClick={handleCreateAvatarClick}
                  className='btn btn-orange'
                >
                  Create Avatar
                </button>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      
      {showIframe && (
        <div className='iframe-container'>
          <button className='close-iframe' onClick={handleCloseIframe}>
            ×
          </button>
          <iframe
            src='https://readyplayer.me/avatar'
            title='Ready Player Me'
            className='iframe-content'
          ></iframe>
        </div>
      )}
    </>
  );
};

export default Navbar;